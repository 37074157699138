
import Vue from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';
import { PitchAgency } from '@/shared/types';

import { Services } from '@/injectables/tokens';
import { OutputServiceContract } from '@/injectables';
import { Routes } from '@/router/routes';

import OutputEditToolbar from '@/components/Output/OutputEditToolbar.vue';
import AddSlideToLibraryDialog from '@/components/Output/AddSlideToLibraryDialog.vue';
import AddSlideMenu from '@/components/Output/AddSlideMenu.vue';

import OutputContainer from '@/entities/output/output-container.vue';

import OutputMainActionsBtn from '@/features/output/output-main-actions.vue';
import ActiveSlideContainer from '@/features/output/active-slide-container.vue';

import { SlideVisibility } from '@/app/graphql';

export default Vue.extend({
  name: 'CreateLibrarySlide',

  components: {
    OutputEditToolbar,
    OutputContainer,
    OutputMainActionsBtn,
    ActiveSlideContainer,
    AddSlideMenu,
    AddSlideToLibraryDialog,
  },

  inject: ['$confirm'],

  useInjectable: [Services.Output],

  data: (): {
    stagedSlide: OutputSlide | null;
    showAddToLibraryDialog: boolean;
  } => ({
    showAddToLibraryDialog: false,
    stagedSlide: null,
  }),

  computed: {
    activeSlide(): OutputSlide | undefined {
      return this.slides?.[0] || { ...this.initialSlide };
    },
    slides(): OutputSlide[] {
      return this.$store.getters['output/allLocalSlides'];
    },
    // TODO: Remove this method
    defaultLayout(): string {
      return '';
    },
    defaultSlideId(): string {
      return 's-100';
    },
    initialSlide(): Omit<OutputSlide, 'id'> {
      return {
        _id: this.defaultSlideId,
        order: 100,
        name: `New Slide - ${Date.now()}`,
        custom: true,
        layout: this.defaultLayout,
        shapes: [],
        textItems: [],
        images: [],
        visibility: SlideVisibility.Visible,
        colors: { background: '#FFFFFF', text: '#000000' },
      };
    },
    allAgencies(): PitchAgency[] {
      const defaultAgencyInArray = [this.$store.state.auth.user.Agency];

      if (this.isAdmin) {
        return this.$store.state.client.allAgencies?.list || defaultAgencyInArray;
      }

      return defaultAgencyInArray;
    },
    isSmallerScreen(): boolean {
      return this.$vuetify.breakpoint.width <= 1600;
    },
    outputLoading(): boolean {
      return this.$store.state.output.outputLoading;
    },
    hasUnsavedChanges(): boolean {
      return this.$store.state.output.outputHasBeenChanged;
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAdmin'];
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    agencyId(): string {
      const { agency = '' } = this.$route.params;

      return agency;
    },
    selectedAgency: {
      get(): PitchAgency {
        const { agency: agencyPropertyId = '' } = this.$route.params;
        const foundAgency = this.allAgencies.find(({ PropertyId }) => PropertyId === agencyPropertyId);

        return foundAgency || {};
      },
      set(agency: string): void {
        if (agency === this.selectedAgency?.PropertyId) return;

        const updatedAgency = this.isAdmin ? agency : this.$store.state.auth.user.Agency.PropertyId;

        this.$router.replace({ ...this.$route, params: { ...this.$route.params, agency: updatedAgency } });

        this.getAvailableLayouts({ agency });
        this.getOutputData({ layoutObj: { layout: this.defaultLayout } });
      },
    },
    defaultFont(): string {
      return (this.outputService as OutputServiceContract).defaultOutputFontFamily(this.userAgency);
    },
    userAgency(): string {
      return this.$store.state.auth.user.Agency;
    },
    loading(): boolean {
      return this.$store.state.output.getOutputLoading || this.$store.state.output.outputLoading;
    },
  },

  methods: {
    closeAddToLibraryDialog({ saved }: { saved: boolean }): void {
      this.showAddToLibraryDialog = false;
      this.stagedSlide = null;
      if (saved) this.handleCloseOutputEditor({ bypass: true });
    },
    openAddSlideDialog({ slide }: { slide: OutputSlide }): void {
      this.stagedSlide = slide;
      this.showAddToLibraryDialog = true;
    },
    getOutputData({ layoutObj }: { layoutObj: { layout: string } }): void {
      this.$store.dispatch('output/getLayoutAdditionalInfo', { ...layoutObj });
    },
    async updateSlideType(slide: OutputSlide): Promise<void> {
      const canProceed = await this.checkChanges();

      if (canProceed) this.setSlide(slide);
    },
    getAvailableLayouts(payload?: { agency: string }): void {
      const agency = payload?.agency || this.agencyId;

      return this.$store.dispatch('output/getAvailableLayouts', { agency });
    },
    async closeConfirmation() {
      return (
        await this.$confirm.show({
          title: 'Unsaved changes',
          body: 'Are you sure you want to leave without saving?',
          confirmText: 'Leave',
          cancelText: 'Stay',
        })
      ).confirmed;
    },
    async checkChanges(): Promise<boolean> {
      let canProceed = true;

      if (this.hasUnsavedChanges) {
        canProceed = (
          await this.$confirm.show({
            title: 'Discard unsaved changes?',
            body: 'Are you sure you want to update layout without saving your changes?',
            confirmText: 'Discard',
            cancelText: 'Cancel',
          })
        ).confirmed;
      }

      return canProceed;
    },
    async updateLocalLayout(layoutObj: { layout: string }): Promise<void> {
      const canProceed = await this.checkChanges();

      if (canProceed) {
        this.$store.dispatch('output/clearOutputHasChanged');
        this.getOutputData({ layoutObj });
        await this.setSlide();
        this.$store.dispatch('output/resetActionHistory');
        this.$store.dispatch('output/saveOutputSnapshot');
      }
    },
    setSlide(slide?: OutputSlide): void {
      this.$store.dispatch('output/setSingleSlide', { ...this.initialSlide, ...slide, _id: this.defaultSlideId });

      if (!slide) {
        this.$store.dispatch('output/updateActiveSlide', this.defaultSlideId);
        this.$store.dispatch('output/clearOutputHasChanged');
      }
    },
    saveOutput(): void {
      this.openAddSlideDialog({ slide: this.activeSlide });
    },
    async handleCloseOutputEditor(payload: { bypass: boolean }): Promise<void> {
      if (!payload.bypass && this.hasUnsavedChanges && !(await this.closeConfirmation())) {
        return;
      }

      if (this.hasUnsavedChanges) {
        this.$store.dispatch('output/clearOutputHasChanged');
      }

      if (window.history.length > 2) {
        return this.$router.go(-1);
      }

      this.$router.push({ name: Routes.Home });
    },
  },

  async mounted(): Promise<void> {
    if (this.isAdmin) this.$store.dispatch('client/getAgencies');

    const data = await this.getAvailableLayouts();
    let defaultLayout = data.find(layout => layout.isDefault);

    if (!defaultLayout) {
      [defaultLayout] = data;
    }

    this.getOutputData({ layoutObj: { layout: defaultLayout?.id } });
    this.setSlide();
  },

  async beforeRouteLeave(to, from, next): Promise<void> {
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },
});
